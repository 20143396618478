<template>
  <XForm ref="tagsFilterForm">
    <XTextField
        ref="textField"
        :id="id"
        label="Tags Filter"
        :value="value"
        @input="handleInput"
        @immediate-input="handleImmediateInput"
        :delay="delay"
        :rules="tagsFilterRules"
        :tooltip="tagsFilterTooltip"
        @keydown.up="moveSelection(-1)"
        @keydown.down="moveSelection(1)"
        @keydown.enter="selectSuggestion"
        @focus="handleFocus(true)"
        @blur="handleFocus(false)"/>
    <div v-show="showSuggestions" class="suggestions">
      <v-list dense>
        <v-list-item
            v-for="(suggestion, i) of suggestions"
            :key="i"
            :class="`suggestion-list-item ${i === selection ? 'cursor' : ''}`"
            @click="addSuggestion(suggestion.suggestion, suggestion.mask)">
          <v-list-item-content>
            <v-list-item-title>
              {{ suggestion.beforeMask }}<span class="v-list-item__mask">{{
                suggestion.mask
              }}</span>{{ suggestion.afterMask }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </XForm>
</template>

<script>
import XTextField from '@/components/basic/XTextField';
import XForm from '@/components/basic/XForm';

export default {
  name: 'TagQueryTextField',
  components: {
    XForm,
    XTextField,
  },
  props: {
    value: String,
    id: String
  },
  data() {
    return {
      tagsFilterRules: [],
      tagsFilterTooltip: undefined,
      tags: [],
      suggestions: [],
      focus: false,
      immediateValue: '',
      delay: 4000,
      selection: -1,
      maxSuggestions: 20,
    };
  },
  created() {
    this.services.explorerStatus.checkTagSyntax(this.value, (response) => {
      this.tagsFilterTooltip = undefined;
      this.tagsFilterRules = [];
      this.$nextTick(() => {
        this.$refs.tagsFilterForm.validate();
      });
      this.$emit('count', response.count);
    }, (error) => {
      const data = error.response.data;
      if (data.error === 'ESS_CREATE_TAG_QUERY') {
        error = data.message.substring(0, 1).toUpperCase() + data.message.substring(1) + '.';
        this.tagsFilterTooltip = error;
        this.tagsFilterRules = [v => v !== this.value];
        this.$nextTick(() => {
          this.$refs.tagsFilterForm.validate();
        });
      }
    });
    this.services.explorerStatus.getExplorerTags((tags) => {
      this.tags = tags;
    });
  },
  watch: {
    suggestions(value) {
      if (!value) this.selection = -1;
    },
  },
  computed: {
    showSuggestions() {
      return this.focus && this.suggestions.length > 0;
    },
  },
  methods: {
    handleImmediateInput(value) {
      this.immediateValue = value;
      this.tagsFilterTooltip = undefined;
      let results = [];
      if (this.tags.length) {
        if (value && value.length) {
          const regex = /[a-zA-Z0-9!_\-=.]+/;
          let matches = value.match(regex);
          const caretPosition = this.$refs.textField.$refs.input.$refs.input.selectionStart;
          let offset = 0;
          while (matches != null) {
            offset--;
            matches = value.charAt(caretPosition + offset).match(regex);
          }
          offset++;
          const match = value.substring(caretPosition + offset, caretPosition);
          results = this.tags.filter(function (x) {
            if (this.count < this.maxSuggestions && x.includes(match)) {
              this.count++;
              return true;
            }
            return false;
          }, {
            count: 0,
            maxSuggestions: this.maxSuggestions,
          });
          for (let i = 0; i < results.length; i++) {
            const index = results[i].indexOf(match);
            results[i] = {
              suggestion: results[i],
              beforeMask: results[i].substring(0, index),
              mask: match,
              afterMask: results[i].substring(index + match.length),
            };
          }
        }
      }
      this.suggestions = results;
    },
    handleInput(value) {
      if (this.addedSuggestionTime && new Date().getTime() - this.addedSuggestionTime.getTime() <= this.delay) return;
      this.emitInput(value);
    },
    emitInput(value) {
      this.services.explorerStatus.checkTagSyntax(value, (response) => {
        this.tagsFilterTooltip = undefined;
        this.tagsFilterRules = [];
        this.$nextTick(() => {
          this.$refs.tagsFilterForm.validate();
        });
        this.$emit('input', value);
        this.$emit('count', response.count);
      }, (error) => {
        const data = error.response.data;
        let errorMessage = 'Tag query syntax error.';
        if (data.error === 'ESS_TAG_QUERY') {
          errorMessage = data.message.substring(0, 1).toUpperCase() + data.message.substring(1) + '.';
        }
        this.tagsFilterTooltip = errorMessage;
        this.tagsFilterRules = [v => v !== value];
        this.$nextTick(() => {
          this.$refs.tagsFilterForm.validate();
        });
      });
    },
    addSuggestion(suggestion, mask) {
      let value = this.immediateValue;
      const caretPosition = this.$refs.textField.$refs.input.$refs.input.selectionStart;
      value = value.substring(0, caretPosition - mask.length) + suggestion + value.substring(caretPosition);
      setTimeout(() => {
        const newPosition = caretPosition - mask.length + suggestion.length;
        this.$refs.textField.$refs.input.$refs.input.focus();
        this.$refs.textField.$refs.input.$refs.input.setSelectionRange(newPosition, newPosition);
      }, 16);
      this.emitInput(value);
      this.addedSuggestionTime = new Date();
      this.suggestions = [];
      this.$nextTick(() => {
        this.$refs.textField.$refs.input.focus();
      });
    },
    handleFocus(value) {
      setTimeout(() => {
        this.focus = value;
      }, 500);
    },
    moveSelection(value) {
      let selection = this.selection;
      selection += value;
      if (selection < 0) selection = this.suggestions.length - 1;
      else if (selection > this.suggestions.length - 1) selection = 0;
      this.selection = selection;
    },
    selectSuggestion() {
      if (this.selection >= 0) {
        const suggestion = this.suggestions[this.selection];
        this.addSuggestion(suggestion.suggestion, suggestion.mask);
      }
    },
  },
};
</script>

<style scoped>
.suggestions {
  box-shadow: 0 4px 6px 0 rgb(32 33 36 / 28%);
  overflow-y: auto;
  max-height: 304px;
  position: absolute;
  z-index: 2;
}

.suggestion-list-item {
  cursor: pointer;
  transition: .3s cubic-bezier(.25, .8, .5, 1);
}

.suggestion-list-item.cursor {
  background-color: var(--v-list-item-cursor-base);
}

.suggestion-list-item:not(.cursor):hover {
  background-color: var(--v-list-item-hover-base);
}
</style>