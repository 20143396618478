var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subscriber-category"},[_c('div',{staticClass:"subscriber-resource"},[_c('div',{staticClass:"subscriber-resource-type"},[_c('div',{staticClass:"text-subtitle-2"},[_vm._v(" Resource ")]),_c('div',{staticClass:"resource-type"},[_c('associatedOption',{key:_vm.subscriberType,attrs:{"type":"select","field":"subscriberType","fieldAttributes":_vm.result.fields['subscriberType'],"templateContent":_vm.result,"value":_vm.subscriberType},on:{"input":function($event){return _vm.updateCategories(...arguments)}}}),_c('input',{staticClass:"selectPreviousValue",attrs:{"type":"hidden"},domProps:{"value":_vm.result.fields['subscriberType'].fieldAttrInput[
                'data-originalvalue'
              ]}})],1)]),(_vm.subscriberType === 'explorer')?_c('div',{staticClass:"tag-query"},[_c('TagQueryTextField',{attrs:{"id":"configure_and_run_tests_dialog_tag_query"},on:{"count":function($event){_vm.tagQueryExplorerCount = $event},"input":_vm.handleTagQueryInput},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}}),(_vm.tagQueryExplorerCount !== undefined)?_c('div',{staticClass:"explorer-count"},[_vm._v(" Explorers: "+_vm._s(_vm.tagQueryExplorerCount)+" ")]):_vm._e()],1):(_vm.subscriberType !== '0')?_c('associatedOption',{attrs:{"type":"combobox","field":"category","fieldAttributes":{
            name: _vm.categoryName,
            options: _vm.result.fields['category'].associatedOption,
          },"fieldAttrInput":{ class: 'required' },"templateContent":_vm.result,"value":_vm.category},on:{"input":function($event){return _vm.categorySelectorChanged(...arguments)}}}):_vm._e(),(_vm.subscriberType != 0 && _vm.subscriberType !== 'explorer')?_c('fieldsValues',{attrs:{"field":"n_subscriberId","fieldAttributes":{
        ...this.result.fields['n_subscriberId'],
        additionlAddingOptions: {
          c_category: _vm.categoryArray,
          n_type: _vm.subscriberType,
          catReaddOnly: true,
          category: _vm.subCategory
        }
      },"value":this.result.valueLine.n_subscriberId,"fieldAttrInput":{ class: 'required' },"templateContent":{ ..._vm.result, enableAdd: true }},on:{"input":function($event){return _vm.updateSubscriberValue(...arguments)}}}):_vm._e()],1),(_vm.showDialog)?_c('noticeDialog',{attrs:{"title":"Notice","message":_vm.languagePack('testJSConfig', 'subscriberTypeChangedFailedDialog'),"action":true,"maxWidth":"650"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }