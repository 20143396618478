<template>
  <v-form ref="form" :value="value" @input="$emit('input', $event)" @submit.prevent>
    <slot/>
  </v-form>
</template>

<script>
export default {
  name: 'XForm',
  props: {
    value: Boolean,
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
  },
};
</script>

<style scoped>

</style>