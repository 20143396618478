<template>
  <div class="subscriber-category">
    <div class="subscriber-resource">
      <div class="subscriber-resource-type">
        <div class="text-subtitle-2">
          Resource
        </div>
        <div class="resource-type">
          <associatedOption
              type="select"
              field="subscriberType"
              :fieldAttributes="result.fields['subscriberType']"
              :templateContent="result"
              @input="updateCategories(...arguments)"
              :value="subscriberType"
              :key="subscriberType"/>
          <input
              type="hidden" class="selectPreviousValue" :value="
                result.fields['subscriberType'].fieldAttrInput[
                  'data-originalvalue'
                ]
              "/>
        </div>
      </div>
      <div v-if="subscriberType === 'explorer'" class="tag-query">
        <TagQueryTextField v-model="category" @count="tagQueryExplorerCount = $event" @input="handleTagQueryInput" id="configure_and_run_tests_dialog_tag_query"/>
        <div v-if="tagQueryExplorerCount !== undefined" class="explorer-count">
          Explorers: {{ tagQueryExplorerCount }}
        </div>
      </div>
      <associatedOption
          v-else-if="subscriberType !== '0'"
          type="combobox"
          field="category"
          :fieldAttributes="{
              name: categoryName,
              options: result.fields['category'].associatedOption,
            }"
          :fieldAttrInput="{ class: 'required' }"
          :templateContent="result"
          :value="category"
          @input="categorySelectorChanged(...arguments)"/>
      <fieldsValues
          v-if="subscriberType != 0 && subscriberType !== 'explorer'"
          field="n_subscriberId"
          :fieldAttributes="{
          ...this.result.fields['n_subscriberId'],
          additionlAddingOptions: {
            c_category: categoryArray,
            n_type: subscriberType,
            catReaddOnly: true,
            category: subCategory
          }
        }"
          :value="this.result.valueLine.n_subscriberId"
          :fieldAttrInput="{ class: 'required' }"
          :templateContent="{ ...result, enableAdd: true }"
          @input="updateSubscriberValue(...arguments)"/>
    </div>

    <noticeDialog
        v-if="showDialog" v-model="showDialog" title="Notice" :message="
        languagePack('testJSConfig', 'subscriberTypeChangedFailedDialog')
      " :action="true" maxWidth="650"/>
  </div>

</template>
<script>
import noticeDialog from '@/commonComponents/noticeDialog.vue';
import fieldsValues from '@/commonComponents/fieldsValues.vue';
import {getfilteredTestCaseTypes} from '@/js/testHelpers.js';
import TagQueryTextField from '@/components/extended/TagQueryTextField';
import AssociatedOption from '@/commonComponents/associatedOption';

export default {
  components: {
    AssociatedOption,
    TagQueryTextField,
    fieldsValues,
    noticeDialog,
  },
  props: ['result'],
  data() {
    return {
      subscriberValue: '',
      showDialog: false,
      tagQueryExplorerCount: undefined,
    };
  },
  created() {
    if (typeof this.result.valueLine.n_subscriberId != 'undefined') {
      this.subscriberValue = this.result.valueLine.n_subscriberId;
    }
    this.handleTagQueryInput(this.category);
  },
  computed: {
    categoryName() {
      let categoryName = 'Category';
      if (this.subscriberType === 'explorer') {
        categoryName = 'Tags';
      }
      return categoryName;
    },
    categoryArray() {
      let categoryList = [];
      if (this.subCategory !== '') {
        categoryList.push(this.subCategory);
      }
      return categoryList;
    },
    category: {
      get() {
        let category = '';
        if (
            typeof this.result.valueLine.category != 'undefined' &&
            this.result.valueLine.category != null
        ) {
          category = this.result.valueLine.category;
        }
        return category;
      },
      set(newValue) {
        this.$emit('field-value-changed', 'category', newValue);
      },
    },
    subCategory: {
      get() {
        let category = '';
        if (
            typeof this.result.valueLine.category != 'undefined' &&
            this.result.valueLine.category != null &&
            this.result.valueLine.category !== 'all'
        ) {
          category = this.result.valueLine.category;
        }
        return category;
      },
    },
    subscriberType: {
      get() {
        return this.result.valueLine.subscriberType;
      },
      set(newValue) {
        this.$emit('field-value-changed', 'subscriberType', newValue);
      },
    },

    categorylist() {
      let categorylist = [];
      if (this.result.valueLine.category !== 'all') {
        categorylist = [this.result.valueLine.category];
      }
      return categorylist;
    },
  },
  methods: {
    updateSubscriberValue(subscriber) {
      if (subscriber != null) {
        console.log('sub called', subscriber);
        this.subscriberValue = subscriber;
        let subscriberValue = '';
        subscriberValue = subscriber;
        this.$emit('field-value-changed', 'n_subscriberId', subscriberValue);
      }
    },
    categorySelectorChanged(category) {
      this.$store.commit('additionalSubmitDataChanged', {
        category: undefined,
      });
      this.category = category;
      if (this.category != null && this.subscriberType !== 'explorer') {
        let request = {
          function: 'getSubscribers',
          category: this.category,
          type: this.subscriberType,
          requestType: 'ajax',
        };
        let caller = this;
        this.frameworkAxiosRequest({
          method: 'post',
          url: 'serve.php?f=testing&f2=configureAndRunTests',
          data: request,
        })
            .then(function (response) {
              // caller.subscribersList = response.data.result.json;
              caller.$set(
                  caller.result.fields['n_subscriberId'],
                  'associatedOption',
                  response.data.result.json,
              );
              caller.$nextTick(function () {
                caller.$emit('revalidate');
              });
            })
            .catch(function (error) {
              //handle error
              console.log(error);
            });
      }

    },
    recursiveCheck(testCases, testCaseTypesArray) {
      let stepsCheck = true;
      for (let [index, stepInfo] of Object.entries(testCases)) {
        if (stepsCheck === true) {
          if (index === 'pre' || index === 'post') {
            stepsCheck = this.recursiveCheck(stepInfo, testCaseTypesArray);
            if (!stepsCheck) {
              return false;
            }
          } else {
            Object.keys(stepInfo).forEach((stepType) => {
              if (
                  typeof stepType != 'undefined' &&
                  stepType !== '' &&
                  testCaseTypesArray.indexOf(stepType) === -1
              ) {
                stepsCheck = false;
                return false;
              }
            });
          }
        }
      }
      return stepsCheck;
    },
    updateCategories(type) {
      let storedTestCaseTypes = this.result.additional.testCasesOriginal;
      let testCaseTypes = getfilteredTestCaseTypes(
          type,
          storedTestCaseTypes,
          this.result.additional.noSubscriberAllowedTypes,
      );
      let testCaseTypesArray = [];
      for (let cat in testCaseTypes) {
        for (let index in testCaseTypes[cat]) {
          if (
              !testCaseTypesArray.includes(testCaseTypes[cat][index]['value'])
          ) {
            testCaseTypesArray.push(testCaseTypes[cat][index]['value']);
          }
        }
      }

      let testCases = {};
      if (
          typeof this.result.valueLine.c_obj != 'undefined' &&
          this.result.valueLine.c_obj !== ''
      ) {
        testCases = JSON.parse(this.result.valueLine.c_obj);
      }

      let stepsCheck = this.recursiveCheck(testCases, testCaseTypesArray);

      if (stepsCheck === false) {
        this.showDialog = true;
        let oldType = this.subscriberType;
        this.subscriberType = type;
        this.$nextTick(() => {
          this.subscriberType = oldType;
        });
      } else {
        this.subscriberType = type;
        // console.log("updating");
        this.$emit('field-value-changed', 'subscriberType', type);
        // updating previous value
        //  $selectContainer.find("input.selectPreviousValue").val(subscriberType);
        if (this.subscriberType === 0) {
          //  $selectContainer.find(".subscriberDetails").hide();
        } else {
          // $selectContainer.find(".subscriberDetails").show();
          this.$set(this.result.fields.category, 'associatedOption', []);
          let request = {
            function: 'getSubscribersCategories',
            type: this.subscriberType,
            requestType: 'ajax',
          };
          let caller = this;
          this.frameworkAxiosRequest({
            method: 'post',
            url: 'serve.php?f=testing&f2=configureAndRunTests',
            data: request,
          })
              .then(function (response) {
                // //console.log(response);
                let data = response.data.result.json;
                if (caller.subscriberType !== 'explorer') {
                  data.unshift(['all', 'All']);
                }
                caller.categoryList = data;
                caller.$set(
                    caller.result.fields.category,
                    'associatedOption',
                    data,
                );
                caller.$store.commit('dialogResultChanged', caller.result);
                // console.log("in response ", caller.categoryList);
                if (caller.subscriberType !== 'explorer') {
                  caller.categorySelectorChanged('all');
                }
              })
              .catch(function (error) {
                //handle error
                console.log(error);
              });
        }
      }
    },
    handleTagQueryInput(value) {
      this.$store.commit('additionalSubmitDataChanged', {
        category: value,
      });
    },
  },
};
</script>

<style scoped>
.subscriber-category {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.subscriber-resource {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 16px;
}

.subscriber-resource-type {
  display: flex;
  gap: 40px;
  align-items: center;
}

.resource-type {
  flex-grow: 1;
}

.tag-query {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.explorer-count {
  text-align: right;
  padding-right: 12px;
}
</style>