<template>
  <div class="subscriber-panel-content">
    <div class="subscriber-panel-content-resource">
      <subscriberCategory
          :result="result" v-on="$listeners"/>
      <fieldsValuesContainer
          v-if="result.valueLine.subscriberType == 'explorer'"
          field="n_maxRuntime"
          :result="{ ...result, enableAdd: true }"
          v-on="$listeners"
          :valueLine="result.valueLine"
          class="mt-6"></fieldsValuesContainer>
      <div class="" v-if="typeof result.fields.n_systemId != 'undefined'">
        <fieldsValuesContainer
            field="n_systemId" :result="{
              ...result,
              fields: {
                ...result.fields,
                n_systemId: { ...result.fields.n_systemId, type: 'select' },
              },
              enableAdd: true,
            }" v-on="$listeners" :valueLine="result.valueLine"></fieldsValuesContainer>
      </div>
    </div>
    <div class="subscriber-panel-content-variables">
      <singleAccordion
          id="testLocalVariables" :title="'Local Variables (' + Object.keys(localPairArray).length + ')'"
      >
        <div class="mt-3">
          <lineFieldsArray
              :fieldAttrRow="{}"
              :fields="fields"
              :value="localPairArray"
              @line-field-input="checkInput('testlocal', ...arguments)"
              :headerTitle="false"
              :addable="true"
              :templateContent="result"
              :isValid="isValid"
              @revalidate="$emit('revalidate')"
              linePrefix="testLocalVaraibles"></lineFieldsArray>
        </div>
      </singleAccordion>
      <singleAccordion
          id="testGlobalVariables" :title="'Group Variables (' + Object.keys(globalPairArray).length + ')'"
      >
        <div class="mt-3">
          <lineFieldsArray
              :fieldAttrRow="{}"
              :fields="fields"
              :value="globalPairArray"
              @line-field-input="checkInput('testgroup', ...arguments)"
              :headerTitle="false"
              :addable="true"
              :templateContent="result"
              :isValid="isValid"
              @revalidate="$emit('revalidate')"
              linePrefix="testGlobalVaraibles"></lineFieldsArray>
        </div>
      </singleAccordion>

      <input
          type="hidden" name="c_testVariables" :value="JSON.stringify(localVal)"/>
      <div class="mt-3"></div>
    </div>
  </div>
</template>
<script>
import subscriberCategory from '@/components/legacy/subscriberCategory.vue';
import lineFieldsArray from '@/commonComponents/lineFieldsArray.vue';
import fieldsValuesContainer from '@/commonComponents/fieldsValuesContainer.vue';
import {updateTestVariables} from '@/js/testHelpers.js';
import singleAccordion from '@/commonComponents/singleAccordion.vue';

export default {
  components: {
    lineFieldsArray,
    subscriberCategory,
    singleAccordion,
    fieldsValuesContainer,
  },
  data() {
    return {
      operatorList: [],

      // pairArray: [],
      // localVal: {},
      fields: {
        // type: {
        //   type: "select",
        //   options: [
        //     ["testgroup", "testgroup"],
        //     ["testlocal", "testlocal"],
        //   ],
        //   name: "Type",
        //   default: "testlocal",
        //   fieldAttrInput: { class: "required" },
        // },
        name: {
          type: 'text',
          name: 'Name',
          fieldAttrInput: {class: 'required noSpace'},
        },
        /*  equal: {
          type: "string",
          colAttrs: {
            style: "max-width: 23px;min-width: 23px;",
            class: "mt-1",
          },
          string: "=",
        },*/
        value: {
          type: 'text',
          name: 'Value',
          // value is not reuired since there are values declared in templates which are dynamically inserted
          fieldAttrInput: {class: ''},
        },
        flags: {
          type: 'text',
          name: 'Start Parameter',

          default: {
            start: 0,
          },
          colAttrs: {
            style: 'max-width: 200px;min-width: 200px;',
            class: 'mt-n3 pl-2',
          },
          inputFieldFormatter: 'variablesFlagsFormatter',
        },

        remove: {
          inputFieldFormatter: 'trash',
          input: false,
          colAttrs: {
            style: 'max-width: 23px;min-width: 35px;',
            class: 'mt-2',
          },
        },
      },
    };
  },
  props: ['field', 'fieldAttributes', 'result', 'value', 'isValid'],

  created() {
    //console.log("created");
    let request = {
      function: 'getSeleniumTemplates',

      requestType: 'ajax',
    };
    let caller = this;
    this.frameworkAxiosRequest({
      method: 'post',
      url: 'serve.php?f=configuration&f2=nocsPacketTemplates',
      data: request,
    })
        .then(function (response) {
          // caller.templates = response.data.result.json.templateNames;
          // caller.$delete(caller.templates, caller.excludeId);
          //caller.templateObjects = response.data.result.json.templateObjects;
          caller.addObjectToVariables(response.data.result.json.templateObjects);
        })
        .catch(function () {
          //handle error
        });
  },

  computed: {
    localVal: {
      get() {
        let localVal = {};
        // console.log(this.value);
        if (
            typeof this.value != 'undefined' &&
            this.value != '' &&
            this.value != null
        ) {
          localVal = JSON.parse(this.value);
          if (
              typeof localVal != 'object' ||
              Array.isArray(localVal) ||
              localVal == null
          ) {
            localVal = {};
          }
        }
        return localVal;
      },
      set(localVal) {
        this.$emit('input', JSON.stringify(localVal));
      },
    },
    localPairArray() {
      /* let pairArray = [];
      if (
        this.localVal != null &&
        typeof this.localVal.testlocal != "undefined"
      ) {
      
        for (const [index, value] of Object.entries(this.localVal.testlocal)) {
          pairArray.push({ index: index, value: value });
        }
       
      }
      return pairArray;*/
      return this.getTestVariablesValues('testlocal');
    },
    globalPairArray() {
      /*  let pairArray = [];
      if (
        this.localVal != null &&
        typeof this.localVal.testgroup != "undefined"
      ) {
        //  for (const [type, info] of Object.entries(this.localVal)) {
        for (const [index, value] of Object.entries(this.localVal.testgroup)) {
          pairArray.push({ index: index, value: value });
        }
        // }
      }
      return pairArray;*/
      return this.getTestVariablesValues('testgroup');
    },
  },
  methods: {
    getTestVariablesValues(variableIndex) {
      let pairArray = [];
      if (
          this.localVal != null &&
          typeof this.localVal[variableIndex] == 'object'
      ) {
        if (!Array.isArray(this.localVal[variableIndex])) {
          console.log('here');
          for (const [index, value] of Object.entries(
              this.localVal[variableIndex],
          )) {
            pairArray.push({
              name: index,
              value: value,
              flags: {
                start: 0,
              },
            });
          }
          this.checkInput(variableIndex, pairArray);
        } else {
          pairArray = this.localVal[variableIndex];
        }
      }
      return pairArray;
    },
    getTemplateIds() {
    },
    addObjectToVariables(object) {
      let testVariables = JSON.parse(this.result.valueLine.c_testVariables);
      // console.log(testVariables);
      let steps = JSON.parse(this.result.valueLine.c_obj);
      this.detailedObjectAdder(object, testVariables, steps);
      // console.log(steps);
    },
    detailedObjectAdder(object, testVariables, steps) {
      Object.keys(steps).forEach((stepKey) => {
        let stepInfo = steps[stepKey];
        if (stepKey == 'pre' || stepKey == 'post') {
          this.detailedObjectAdder(object, testVariables, stepInfo);
        } else {

          if (Object.keys(stepInfo)[0] == 'Web_Driver') {
            let template = Object.values(stepInfo)[0];
            template.commands.forEach((element) => {
              if (element.command == 'template') {
                updateTestVariables(
                    element.id,
                    object,
                    testVariables,
                    this,
                    [],
                );
              }
            });
          }
        }
      });
    },

    checkInput(type, value) {
      /*let localVal = {};
      value.forEach((element) => {
        // if (typeof localVal[element.type] == "undefined") {
        //   localVal[element.type] = {};
        // }
        if (typeof localVal[element.name] == "undefined") {
          localVal[element.name] = element.value;
        }
      });*/
      this.$set(this.localVal, type, value);
      // this.getPairArray();
      this.$emit('input', JSON.stringify(this.localVal));
    },
  },
};
</script>

<style scoped>
.subscriber-panel-content {
  display: flex;
  gap: 10px;
  width: 100%;
  margin-bottom: 20px;
}

.subscriber-panel-content-resource {
  flex-basis: 41.5%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.subscriber-panel-content-resource >>> .row.mt-6.no-gutters {
  margin-top: 0 !important;
}

.subscriber-panel-content-resource >>> .v-text-field.v-text-field--enclosed .v-text-field__details {
  display: none;
}

.subscriber-panel-content-variables {
  flex-basis: 58.5%;
}
</style>